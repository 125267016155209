import { callApi, callApiFileStorage, callApiNoBlocking } from '../service'
import swal from "sweetalert"

export const userProfileService = {
    getAll,
    getById,
    create,
    edit,
    uploadMigrationFile,
    getUsersByFilter,
    getCatalogs,
    editAdditionalEmail,
    getUsersByFilterPage,
    VerifyAccount
};

async function getAll(pageIndex, pageCount, filterBy, filter, orderBy, ascending) {
    return callApiNoBlocking(`/BackOffice/UserProfile?pageIndex=${pageIndex}&pageCount=${pageCount}&filterBy=${filterBy}&filter=${filter}&orderBy=${orderBy}&ascending=${ascending}`, 'GET')
        .then((result) => {
            if (result.responseCode === "00") {
                return result.data;
            } else {
                return { totalPages: 0, list: [] };
            }
        });
}

async function getById(id) {
    return callApi('/Account/Get?userId=' + id, 'GET')
        .then((result) => {
            if (result.responseCode === "00") {
                return result.data;
            } else {
                return {};
            }
        });
}

async function getCatalogs() {
    return callApi('/BackOffice/UserProfileCatalogs', 'GET')
        .then((result) => {
            if (result.responseCode === "00") {
                return result.data;
            } else {
                return {};
            }
        });
}

async function create(param) {
    return callApi('/Account/Create', 'POST', param)
        .then((result) => {
            if (result.responseCode === "00") {
                swal({ text: "Usuario guardado exitosamente", icon: "success" })
                    .then(() => window.location.href = "/users");
            } else {
                swal({ text: result.responseMessage, icon: "error" });
            }
        });
}


async function edit(param) {
    return callApi('/Account/Edit', 'POST', param)
        .then((result) => {
            if (result.responseCode === "00") {
                swal({ text: "Usuario guardado exitosamente", icon: "success" })
                    .then(() => window.location.href = "/users");
            } else {
                swal({ text: result.responseMessage, icon: "error" });
            }
        });
}

async function editAdditionalEmail(param) {
    return callApi('/Account/EditAdditionalEmail', 'POST', param)
        .then((result) => {
            if (result.responseCode === "00") {
                swal({ text: "Correo actualizado correctamente.", icon: "success" });
            } else {
                swal({ text: result.responseMessage, icon: "error" });
            }
        });
}
async function uploadMigrationFile(companyId, file) {
    const formData = new FormData();
    formData.append("file", file);
    return callApiFileStorage('/Account/UploadMigrationFile?companyId=' + companyId, 'POST', formData, false)
        .then((result) => {
            if (result.responseCode === "00") {
                swal({ text: "El proceso de carga de usuarios inicio correctamente", icon: "success" }).then(() => {
                    window.location.href = "/users";
                })
                return true;
            } else {
                swal({ text: result.responseMessage, icon: "error" });
                return false;
            }
        });
}

async function getUsersByFilter(params) {
    return callApi('/backoffice/UserProfile/GetByFilter/' + params.Departments + '/' + params.JobRoleId+ '/' + params.BranchOfficeId, 'GET').then((result) => {

        if (result.responseCode === '00') {
            return result.data;
        } else {
            alert(result.responseMessage);
            return null;
        }

    });
}

async function getUsersByFilterPage(params) {
    return callApiNoBlocking('/backoffice/UserProfile/GetByFilterPage/' + params.Departments + '/' + params.JobRoleId + '/' + params.BranchOfficeId + '/' + params.PageIndex + '/' + params.PageCount, 'GET',).then((result) => {

        if (result.responseCode === '00') {
            return result.data;
        } else {
            alert(result.responseMessage);
            return null;
        }

    });
}

async function VerifyAccount(param) {
    return callApi(`/Account/VerifyEmailAccount?userId=${param}`, 'GET')
        .then((result) => {
            if (result.responseCode === "00") {
                swal({ text: "Correo verificado correctamente", icon: "success" })
                return result.data; 
            } else {
                swal({ text: result.responseMessage, icon: "error" });
            }
        });
}