import React, { useState, useEffect } from "react";
import "./userActivity.scss";
import { backendService } from "./../../services/backend.service";
import Moment from "react-moment";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import "moment/locale/es";
import { directoryInfoService } from "../../services/directoryService.service";
import { postulanteService } from "../../services/postulantJob.service";
import { ecommerceService } from "../../services/ecommerce.service";
import swal from "sweetalert";

export function UserActivity() {
  const [activeTab, setActiveTab] = useState("1");
  const [activeMembership, setActiveMembership] = useState("");
  const [userActivity, setUserActivity] = useState([]);
  const [userDirectry, setUserDirectory] = useState([]);
  const [userPostulant, setUserPostulant] = useState([]);

  useEffect(() => {
    backendService.userProfileActivity(false).then((data) => {
      setUserActivity(data);
    });

    directoryInfoService.getMyDirectory().then((data) => {
      setUserDirectory(data);
    });

    postulanteService.GetByUserId(localStorage["userId"]).then((data) => {
      setUserPostulant(data);
    });
  ecommerceService.membershipStatus().then((result) => {
    setActiveMembership(result.subscriptionId);
  });
}, []);


  const deleteDirectory = (id) => {
    directoryInfoService.deleteDirectory(id)

      .then((dataDirectoryInfo) => {
        debugger
        console.log(dataDirectoryInfo)
        if (dataDirectoryInfo.active == false){
        swal({text: "El directorio ha sido dado de baja", icon: "success", closeOnClickOutside: false});
        directoryInfoService.getMyDirectory().then((dataDirectoryInfo) => {
          setUserDirectory(dataDirectoryInfo);
        });}
    else {
      swal({text: "No se pudo dar de baja", icon: "error", closeOnClickOutside: false});
    }
      })
      .catch((error) => {
        console.log(error);
        swal({text: "No se pudo dar de baja", icon: "error", closeOnClickOutside: false});
      });
  };

  const cancelMembership = () => {
    swal({
      title: "¿Estas seguro?",
      text: "¡Deseas cancelar la membresía!",
      icon: "warning",
      buttons: ["Regresar", "Si, cancelar"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        ecommerceService.cancelMembership(activeMembership).then((result) => {
          if (result.responseCode == "00") {
            setActiveMembership("");
            swal({
              text: "La membresía se canceló correctamente",
              icon: "success",
            });
          } else swal({ text: result.responseMessage, icon: "error" });
        });
      }
    });
  };

  return (
    <div className="content_user_activity ">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === "1" ? "active" : ""}
            onClick={() => setActiveTab("1")}
          >
            Actividades del usuario
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "2" ? "active" : ""}
            onClick={() => setActiveTab("2")}
          >
            Servicios publicados
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "3" ? "active" : ""}
            onClick={() => setActiveTab("3")}
          >
            Mis postulaciones
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "4" ? "active" : ""}
            onClick={() => setActiveTab("4")}
          >
            Membresía
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <div className="content_history_activity card">
            <h5 className="text fnt_medium mb-3">Historial de actividades</h5>
            <div className="table-responsive">
              <table className="table table-sm table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Tipo de actividad</th>
                    <th scope="col" className="min_width">Actividad</th>
                    <th scope="col" className="min_width">Fecha de término</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(userActivity) &&
                    userActivity.map((item, index) => (
                      <tr key={index}>
                        <td> {item.type}</td>
                        <td>{item.name}</td>
                        <td>
                          <Moment
                            locale="es"
                            format="DD [de] MMMM [de] YYYY hh:mm [hrs]"
                          >
                            {item.expirationDate}
                          </Moment>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </TabPane>
        <TabPane tabId="2">
          <div className="content_services_profile">
            <h5 className="text fnt_medium mb-3">
              Lista de servicios anunciados
            </h5>
            <div className="table-responsive">
              <table className="wrapper_table table table-sm table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nombre completo</th>
                    <th scope="col">Categoría</th>
                    <th scope="col">Subcategoría</th>
                    <th scope="col" className="min_width">Vigencia</th>
                    <th scope="col">Status</th>
                    <th scope="col">Baja de Servicio</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(userDirectry) &&
                    userDirectry.map((p, index) => (
                      <tr key={index}>
                        <td>{p.directoryInfoId}</td>
                        <th>
                          {p.directoryInfoStatusId === 6 ? (
                            <Link
                              to={"/register-services/" + p.directoryInfoId}
                            >
                              <span className="link_data">{p.fullName}</span>{" "}
                            </Link>
                          ) : (
                            <span>{p.fullName}</span>
                          )}
                        </th>
                        <td>{p.categoryServiceName}</td>
                        <td>{p.subCategoryServiceName}</td>
                        <td>
                          <Moment
                            locale="es"
                            format="DD [de] MMMM [de] YYYY hh:mm [hrs]"
                          >
                            {p.createDate}
                          </Moment>
                        </td>
                        <td style={{ width: "15%" }}>
                          <span
                            className={
                              p.directoryInfoStatusId === 1
                                ? "item_badge pending"
                                : p.directoryInfoStatusId === 2
                                ? "item_badge success"
                                : p.directoryInfoStatusId === 3
                                ? "item_badge rejected"
                                : "item_badge defeated"
                            }
                          >
                            {p.directoryInfoStatusName}
                          </span>
                        </td>
                        {p.directoryInfoStatusId === 2 &&
                          <td>
                            <button className="item_badge rejected" onClick={() => deleteDirectory(p.directoryInfoId)}>
                              Dar de Baja
                            </button>
                          </td>}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </TabPane>
        <TabPane tabId="3">
          <div className="content_services_profile">
            <h5 className="text fnt_medium mb-3">
              Lista de mis postulaciones
            </h5>
            <div className="table-responsive">
              <table className="wrapper_table table table-sm table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col" className="min_width">Cargo o área </th>
                    <th scope="col">Lugar</th>
                    <th scope="col">Categoría</th>
                    <th scope="col">Empresa</th>
                    <th scope="col" className="min_width">Fecha de postulación</th>
                    {/* <th scope="col">Status</th> */}
                  </tr>
                </thead>
                <tbody>
                  {userPostulant.map((postulant, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <th>
                          <Link to={'/details-job/'+ postulant.jobId}>
                            <span className="link_data">{postulant.jobTitle}</span>
                          </Link>
                      
                      </th>
                      <td>{postulant.jobLocation}</td>
                      <td>{postulant.jobCategory}</td>
                      <td>{postulant.jobCompanyName}</td>
                      <td>
                        <Moment
                          locale="es"
                          format="DD MMMM YYYY"
                        >
                          {postulant.postulationDate}
                        </Moment>
                      </td>
                      {/* <td style={{ width: "15%" }}>
                        <span
                          className={'item_badge pending'}
                        >
                          Estatus
                        </span>
                      </td> */}
                    
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </TabPane>
        <TabPane tabId="4">
          <div className="content_services_profile">
            <h5 className="text fnt_medium mb-3">
              Membresía {activeMembership ? "Activa" : "Inactiva"}
            </h5>
            <div className="mb-3 acount_info_details space">
              {activeMembership && (
                <button
                  type="button"
                  className="btn primary minimum"
                  onClick={cancelMembership}
                >
                  Cancelar membresía
                </button>
              )}
            </div>
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
}
