import React, { useState, useEffect } from "react";
import { ItemSocial } from "./itemSocial";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./prideMusic.scss";

export function PrideMusic() {
  const roleName = useSelector((state) => state.userSettings.roleName);
  const [sectionOn, setSectionOn] = useState(true);
  const fSectionOn = () => {
    setSectionOn(!sectionOn);
  };
  return (
    <section className="wrapper_embassador padd">
      <div className="mb-4 divtop">
        <div className="box_ttl_opt">
          <h2 className="mb-0 text fnt_medium w-100">Música para nosotras</h2>
          <div className="wrp_filter_directory"></div>
        </div>
        <h4 className="ttls fw600 mb-4">
          <div className="box_tl">
            <span className="ico_shop">
              <i className="fas fa-music"></i>
            </span>
            <span className="pl-2 pr-2">Música y programas para nosotras</span>
          </div>
        </h4>
        <p className="drawer_reg_sex w-100 mt-2">
          ¿Conoces a algún artista o grupo que represente al empoderamiento
          femenino? Notifícalo a Concanaco Mx Administrador.
        </p>
      </div>
      <div className="row">
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/PrideMusic/Music_EmpoderateMujer.jpeg" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                Empodérate Mujer
              </h5>
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Podcast
              </h6>
              <br></br>
              <div className="box_data">
                <h6 className="h_line text fnt_medium ">
                  <span>Enlaces</span>
                </h6>
                <div className="item_data_details">
                  <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                  </span>
                  <a
                    className="alinks text-break text-left"
                    href={`https://open.spotify.com/show/210rZT93cvKsoau822Solg?si=f5ed6fc326944c29`}
                    target="_blank"
                  >
                    Spotify
                  </a>
                </div>
                {/* <hr /> */}
                {/* <div className="wrapper_social_links"> */}
                {/* <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/almaguerluisa/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/luisaalmaguerbanda"
                          /> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/PrideMusic/Music_EmpresariasConstruccion.jpeg" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                Empresarias en construcción
              </h5>
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Podcast
              </h6>
              <br></br>
              <div className="box_data">
                <h6 className="h_line text fnt_medium ">
                  <span>Enlaces</span>
                </h6>
                <div className="item_data_details">
                  <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                  </span>
                  <a
                    className="alinks text-break text-left"
                    href={`https://open.spotify.com/show/678vxq0ufuFTROWigTwTvg?si=05e71bb45ef049ca`}
                    target="_blank"
                  >
                    Spotify
                  </a>
                </div>
                {/* <hr />
                  <div className="wrapper_social_links">
                  </div> */}
              </div>
            </div>
          </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/PrideMusic/Music_HistoriaMujeresEmpresarias.jpeg" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                Historia de mujeres empresarias
              </h5>
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Podcast
              </h6>
              <br></br>
              <div className="box_data">
                <h6 className="h_line text fnt_medium ">
                  <span>Enlaces</span>
                </h6>
                <div className="item_data_details">
                  <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                  </span>
                  <a
                    className="alinks text-break text-left"
                    href={`https://open.spotify.com/show/02tlDU2qUCpMxKeVWtGmlF?si=92372c70934b43ca`}
                    target="_blank"
                  >
                    Spotify
                  </a>
                </div>
                {/* <hr />
                  <div className="wrapper_social_links">
                  </div> */}
              </div>
            </div>
          </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/PrideMusic/Music_Cracks.jpeg" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                Cracks con Oso Trava
              </h5>
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Podcast
              </h6>
              <br></br>
              <div className="box_data">
                <h6 className="h_line text fnt_medium ">
                  <span>Enlaces</span>
                </h6>
                <div className="item_data_details">
                  <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                  </span>
                  <a
                    className="alinks text-break text-left"
                    href={`https://open.spotify.com/show/3DhKkwcA6Pkl6s6xI1m9Im?si=b3845bfa53cf44a5`}
                    target="_blank"
                  >
                    Spotify
                  </a>
                </div>
                {/* <hr />
                  <div className="wrapper_social_links">
                  </div> */}
              </div>
            </div>
          </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/PrideMusic/Music_EmpiezaCero.jpeg" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                Se empieza desde cero
              </h5>
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Podcast
              </h6>
              <br></br>
              <div className="box_data">
                <h6 className="h_line text fnt_medium ">
                  <span>Enlaces</span>
                </h6>
                <div className="item_data_details">
                  <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                  </span>
                  <a
                    className="alinks text-break text-left"
                    href={`https://open.spotify.com/show/7CD6oG9nplAM7U53pdj2F9?si=6cf65b64fb754ef1`}
                    target="_blank"
                  >
                    Spotify
                  </a>
                </div>
                {/* <hr />
                  <div className="wrapper_social_links">
                  </div> */}
              </div>
            </div>
          </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/PrideMusic/Music_MujeresEmpoderadas.jpeg" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                Mujeres Empoderadas
              </h5>
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Playlist
              </h6>
              <br></br>
              <div className="box_data">
                <h6 className="h_line text fnt_medium ">
                  <span>Enlaces</span>
                </h6>
                <div className="item_data_details">
                  <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                  </span>
                  <a
                    className="alinks text-break text-left"
                    href={`https://open.spotify.com/playlist/1NU3dDonXZnQKIsLgZC6eP`}
                    target="_blank"
                  >
                    Spotify
                  </a>
                </div>
                {/* <hr />
                  <div className="wrapper_social_links">
                  </div> */}
              </div>
            </div>
          </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/PrideMusic/Music_MusicaParaMujeres.jpeg" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                Música para mujeres
              </h5>
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Playlist
              </h6>
              <br></br>
              <div className="box_data">
                <h6 className="h_line text fnt_medium ">
                  <span>Enlaces</span>
                </h6>
                <div className="item_data_details">
                  <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                  </span>
                  <a
                    className="alinks text-break text-left"
                    href={`https://open.spotify.com/playlist/3MWV5hSd9PGu1DjNVT4XIo`}
                    target="_blank"
                  >
                    Spotify
                  </a>
                </div>
                {/* <hr />
                  <div className="wrapper_social_links">
                  </div> */}
              </div>
            </div>
          </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/PrideMusic/Music_MusicaMujeresD.jpeg" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                Música para mujeres 2
              </h5>
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Playlist
              </h6>
              <br></br>
              <div className="box_data">
                <h6 className="h_line text fnt_medium ">
                  <span>Enlaces</span>
                </h6>
                <div className="item_data_details">
                  <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                  </span>
                  <a
                    className="alinks text-break text-left"
                    href={`https://open.spotify.com/playlist/13LMx8GvWUsZbdBvVOYWlB`}
                    target="_blank"
                  >
                    Spotify
                  </a>
                </div>
                {/* <hr />
                  <div className="wrapper_social_links">
                  </div> */}
              </div>
            </div>
          </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/PrideMusic/Music_DeMujeresPMujeres.jpeg" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                De mujeres para mujeres 💜
              </h5>
              <br></br>
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Playlist
              </h6>
              <br></br>
              <br></br>
              <div className="box_data">
                <h6 className="h_line text fnt_medium ">
                  <span>Enlaces</span>
                </h6>
                <div className="item_data_details">
                  <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                  </span>
                  <a
                    className="alinks text-break text-left"
                    href={`https://open.spotify.com/playlist/154NeOarzy1pHaZ4IYVwsM`}
                    target="_blank"
                  >
                    Spotify
                  </a>
                </div>
                {/* <hr />
                  <div className="wrapper_social_links">
                  </div> */}
              </div>
            </div>
          </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/PrideMusic/Music_MeditacionesParaMujer.jpeg" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                Meditaciones para la mujer
              </h5>
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Playlist
              </h6>
              <br></br>
              <br></br>
              <br></br>
              <div className="box_data">
                <h6 className="h_line text fnt_medium ">
                  <span>Enlaces</span>
                </h6>
                <div className="item_data_details">
                  <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                  </span>
                  <a
                    className="alinks text-break text-left"
                    href={`https://open.spotify.com/album/4jBbyV2o6Q4QCVSM5PokFn`}
                    target="_blank"
                  >
                    Spotify
                  </a>
                </div>
                {/* <hr />
                  <div className="wrapper_social_links">
                  </div> */}
              </div>
            </div>
          </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/PrideMusic/Music_ComoTrabajarEsposo.png" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                ¿Cómo trabajar con tu esposo y no morir en el intento?
              </h5>
              <br></br>
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Podcast | con Gladys Ames
              </h6>
              <div className="box_data">
                <h6 className="h_line text fnt_medium ">
                  <span>Enlaces</span>
                </h6>
                <div className="item_data_details">
                  <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                  </span>
                  <a
                    className="alinks text-break text-left"
                    href={`https://open.spotify.com/episode/5gNpBRRHnH3vjYRRH7NRrE?si=c5a26d91858940c2`}
                    target="_blank"
                  >
                    Spotify
                  </a>
                </div>
                <hr />
                <div className="wrapper_social_links">
                  <ItemSocial
                    socialIcoName="Facebook"
                    socialIco="logo-facebook"
                    socialUrl="https://www.facebook.com/mujerentrueque/"
                  />
                  <ItemSocial
                    socialIcoName="Instagram"
                    socialIco="logo-instagram"
                    socialUrl="https://www.instagram.com/mujerentrueque/"
                  />
                  <ItemSocial
                    socialIcoName="Instagram"
                    socialIco="logo-linkedin"
                    socialUrl="https://www.linkedin.com/in/daniella-mart%C3%ADnez-127a666/"
                  />
                </div>
              </div>
            </div>
          </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/PrideMusic/Music_NegocioProposito.png" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                ¿Mi negocio está asociado con mi propósito?
              </h5>
              <br></br>
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Podcast | con Pablo Valenzuela
              </h6>
              <br></br>
              <div className="box_data">
                <h6 className="h_line text fnt_medium ">
                  <span>Enlaces</span>
                </h6>
                <div className="item_data_details">
                  <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                  </span>
                  <a
                    className="alinks text-break text-left"
                    href={`https://open.spotify.com/episode/73qzA3LE6F2dk6ehIOruY9?si=7a41abe09d564271`}
                    target="_blank"
                  >
                    Spotify
                  </a>
                </div>
                <hr />
                <div className="wrapper_social_links">
                  <ItemSocial
                    socialIcoName="Facebook"
                    socialIco="logo-facebook"
                    socialUrl="https://www.facebook.com/mujerentrueque/"
                  />
                  <ItemSocial
                    socialIcoName="Instagram"
                    socialIco="logo-instagram"
                    socialUrl="https://www.instagram.com/mujerentrueque/"
                  />
                  <ItemSocial
                    socialIcoName="Instagram"
                    socialIco="logo-linkedin"
                    socialUrl="https://www.linkedin.com/in/daniella-mart%C3%ADnez-127a666/"
                  />
                </div>
              </div>
            </div>
          </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/PrideMusic/Music_TareasHijos.png" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                ¿Cómo hacer tareas con tus hijos, trabajar y no volverte loca?
              </h5>
              <br></br>
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Podcast | con Carolina Camacho
              </h6>
              <div className="box_data">
                <h6 className="h_line text fnt_medium ">
                  <span>Enlaces</span>
                </h6>
                <div className="item_data_details">
                  <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                  </span>
                  <a
                    className="alinks text-break text-left"
                    href={`https://open.spotify.com/episode/0otM5jvP6AASMlEKUG1QGI?si=e75422df9b0f47a4`}
                    target="_blank"
                  >
                    Spotify
                  </a>
                </div>
                <hr />
                <div className="wrapper_social_links">
                  <ItemSocial
                    socialIcoName="Facebook"
                    socialIco="logo-facebook"
                    socialUrl="https://www.facebook.com/mujerentrueque/"
                  />
                  <ItemSocial
                    socialIcoName="Instagram"
                    socialIco="logo-instagram"
                    socialUrl="https://www.instagram.com/mujerentrueque/"
                  />
                  <ItemSocial
                    socialIcoName="Instagram"
                    socialIco="logo-linkedin"
                    socialUrl="https://www.linkedin.com/in/daniella-mart%C3%ADnez-127a666/"
                  />
                </div>
              </div>
            </div>
          </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/PrideMusic/Music_MujerCima.png" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                ¿Cómo ser una mujer que llega a la cima?
              </h5>
              <br></br>
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Podcast | con Karla Wheelock
              </h6>
              <br></br>
              <div className="box_data">
                <h6 className="h_line text fnt_medium ">
                  <span>Enlaces</span>
                </h6>
                <div className="item_data_details">
                  <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                  </span>
                  <a
                    className="alinks text-break text-left"
                    href={`https://open.spotify.com/episode/2ukRDvda4zhIIKVtMgCzVF?si=1b0f2889001b4fcf`}
                    target="_blank"
                  >
                    Spotify
                  </a>
                </div>
                <hr />
                <div className="wrapper_social_links">
                  <ItemSocial
                    socialIcoName="Facebook"
                    socialIco="logo-facebook"
                    socialUrl="https://www.facebook.com/mujerentrueque/"
                  />
                  <ItemSocial
                    socialIcoName="Instagram"
                    socialIco="logo-instagram"
                    socialUrl="https://www.instagram.com/mujerentrueque/"
                  />
                  <ItemSocial
                    socialIcoName="Instagram"
                    socialIco="logo-linkedin"
                    socialUrl="https://www.linkedin.com/in/daniella-mart%C3%ADnez-127a666/"
                  />
                </div>
              </div>
            </div>
          </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/PrideMusic/Music_ImportanciaNetworking.png" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                La importancia del Networking... y ahora también virtual
              </h5>
              <br></br>
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Podcast | con Daniella Martínez
              </h6>
              <div className="box_data">
                <h6 className="h_line text fnt_medium ">
                  <span>Enlaces</span>
                </h6>
                <div className="item_data_details">
                  <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                  </span>
                  <a
                    className="alinks text-break text-left"
                    href={`https://open.spotify.com/episode/33ymOFX85uk9gX6oCb5tJz?si=d9a4f1f1b0c44854`}
                    target="_blank"
                  >
                    Spotify
                  </a>
                </div>
                <hr />
                <div className="wrapper_social_links">
                  <ItemSocial
                    socialIcoName="Facebook"
                    socialIco="logo-facebook"
                    socialUrl="https://www.facebook.com/mujerentrueque/"
                  />
                  <ItemSocial
                    socialIcoName="Instagram"
                    socialIco="logo-instagram"
                    socialUrl="https://www.instagram.com/mujerentrueque/"
                  />
                  <ItemSocial
                    socialIcoName="Instagram"
                    socialIco="logo-linkedin"
                    socialUrl="https://www.linkedin.com/in/daniella-mart%C3%ADnez-127a666/"
                  />
                </div>
              </div>
            </div>
          </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/PrideMusic/SexAndTheCity2.png" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                I Am Woman
              </h5>
              <br></br>
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Cynthia Nixon
              </h6>
              <div className="box_data">
                <h6 className="h_line text fnt_medium ">
                  <span>Enlaces</span>
                </h6>
                <div className="item_data_details">
                  <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                  </span>
                  <a
                    className="alinks text-break text-left"
                    href={`https://open.spotify.com/track/3fCiA3meiFSJ8fyFoJAYY7?si=SKKovF5LQ1KP9bkadeNFJg&utm_source=whatsapp&nd=1`}
                    target="_blank"
                  >
                    Spotify
                  </a>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/PrideMusic/Beyonce.png" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                Run the World (Girls)
              </h5>
              <br></br>
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Beyoncé
              </h6>
              <div className="box_data">
                <h6 className="h_line text fnt_medium ">
                  <span>Enlaces</span>
                </h6>
                <div className="item_data_details">
                  <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                  </span>
                  <a
                    className="alinks text-break text-left"
                    href={`https://open.spotify.com/track/1uXbwHHfgsXcUKfSZw5ZJ0?si=zy5dXDN1QJGkQHBvbP5ttQ&utm_source=whatsapp&nd=1`}
                    target="_blank"
                  >
                    Spotify
                  </a>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/PrideMusic/TheBodyguard.png" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                I'm Every Woman
              </h5>
              <br></br>
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Whitney Houston
              </h6>
              <div className="box_data">
                <h6 className="h_line text fnt_medium ">
                  <span>Enlaces</span>
                </h6>
                <div className="item_data_details">
                  <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                  </span>
                  <a
                    className="alinks text-break text-left"
                    href={`https://open.spotify.com/track/2eHj0klWkwRQuIrNlPpCPa?si=SNLp4hANSAyGeXUYXK-I-A&utm_source=whatsapp&nd=1`}
                    target="_blank"
                  >
                    Spotify
                  </a>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/PrideMusic/AliciaKeys.png" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                A Woman's Worth
              </h5>
              <br></br>
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Alicia Keys
              </h6>
              <div className="box_data">
                <h6 className="h_line text fnt_medium ">
                  <span>Enlaces</span>
                </h6>
                <div className="item_data_details">
                  <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                  </span>
                  <a
                    className="alinks text-break text-left"
                    href={`https://open.spotify.com/track/1NRorokmYEtr1koWybvaE4?si=qI4WsYidSYK9D9neatmO7w&utm_source=whatsapp&nd=1`}
                    target="_blank"
                  >
                    Spotify
                  </a>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/PrideMusic/CyndiLauper.png" />
            </div>
            <div className="card-content text-center">
              <h5 className="card-title mb-0 text fnt_medium name_directory">
                Girls Just Want to Have Fun
              </h5>
              <br></br>
              <h6 className="card-title mb-0 text fnt_small name_directory">
                Cyndi Lauper
              </h6>
              <div className="box_data">
                <h6 className="h_line text fnt_medium ">
                  <span>Enlaces</span>
                </h6>
                <div className="item_data_details">
                  <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                  </span>
                  <a
                    className="alinks text-break text-left"
                    href={`https://open.spotify.com/track/4y1LsJpmMti1PfRQV9AWWe?si=ODyzrSmsS6GAGCpsw1H7RQ&utm_source=whatsapp&nd=1`}
                    target="_blank"
                  >
                    Spotify
                  </a>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </article>
      </div>
    </section>
  );
}
