import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import moment from "moment";
import { SliderHome } from "../../components/sliderHome/sliderHome";
import { BoxStories } from "../../components/boxStories/boxStories";
import { BoxSchedule } from "../../components/boxSchedule";
import { companyPostService } from "../../services/companyPost.service";
import { companyPostViewService } from "../../services/compayPostView.service";
import { courseViewService } from "../../services/courseView.service";
import { backendService } from "../../services/backend.service";
import ImgDoodle from "../../assets/imgs/doodles/concafem.gif";

// ==ICONOS==
import IcoTalleres from "../../assets/imgs/iconos/ico_capacitacion.png";
import IcoAudience from "../../assets/imgs/iconos/ico_schedule.png";
import IcoDirectory from "../../assets/imgs/iconos/ico_directory.png";
import "moment/locale/es";

export function Home() {
  const [lastPost, setLastPost] = useState({});
  const [directoryItems, setDirectoryItems] = useState([]);
  const [auditoriumItems, setAuditoriumItems] = useState([]);
  const [ourWorld, setOurWorld] = useState([]);
  const [lastCourse, setLastCourse] = useState([]);
  const [myModal, setMyModal] = useState("inShow");

  useEffect(() => {
    backendService.widgets().then((data) => {
      if (data && data.auditoriumWidget && data.auditoriumWidget.length > 0) {
        let auditoriumItems = data.auditoriumWidget.map(({ data }) => data);
        let listSorted = auditoriumItems.sort(function (a, b) {
          return (
            Math.abs(Date.now() - new Date(a.conferenceDate)) -
            Math.abs(Date.now() - new Date(b.conferenceDate))
          );
        });

        setAuditoriumItems(listSorted);
      }
      if (data.surveyWidget != null) {
        let date = new Date();
        date.setDate(date.getDate() + data.surveyWidget.expirationDays);
        data.surveyWidget.expirationDate = date;
      }

      if (data.directoryInfoWidget && data.directoryInfoWidget.length > 0) {
        setDirectoryItems(data.directoryInfoWidget);
      }
    });
    getLatestPost();
    getOrWorld();
    getLatestCourse();

    setTimeout(() => {
      setDoodle();
    }, 8000)
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2800,
    arrows: false,
    adaptiveHeight: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsWorld = {
    arrows: true,
    dots: false,
    infinite: true,
    swipeToSlide: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 2000,
    adaptiveHeight: true,
    className: "center",
    centerMode: true,
    centerPadding: "60px",
    slidesToScroll: 2,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          className: "center",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          className: "center",
          centerMode: true,
          centerPadding: "30px",
        },
      },
    ],
  };

  // async function getLatestPost() {
  //   companyPostService.getLatest(2).then((result) => setLastPost(result[0]));
  // }

  async function getLatestPost() {
    companyPostService.getLatest(2).then((result) => {
      if (result && result.length > 0) setLastPost(result[0]);
    });
  }

  async function getOrWorld() {
    companyPostService.getLatest(1).then((result) => {
      //debugger
      setOurWorld(result);
    });
  }

  async function getLatestCourse() {
    courseViewService.getLatestCourse().then((result) => {
      let listCourse = JSON.parse(result);
      setLastCourse(listCourse);
    });
  }

  async function setDoodle() {
    setMyModal(myModal === "" );
  }

  return (
    <section className="wrapper_home padd">
      <div className="row row_data_home">
        <Link
          to="/course-view"
          className="col-lg-4 col-md-6 col-sm-12 link_data_home"
        >
          <h5 className="mb-1 text fnt_medium">Cartelera</h5>
          <div className="item_data_home shadows">
            <div className="box_company_home">
              <div className="label_data text-muted mb-1">
                Eventos disponibles
              </div>
              <Slider {...settings}>
                {lastCourse &&
                  lastCourse.map((item) => (
                    <div className="item_cards_directory" id={item.CourseId}>
                      <h6 className="mb-1 text fnt_medium">
                        <i className="fa fa-video"></i> {item.Name}
                      </h6>
                      <h6 className="label_data text-muted">
                        {" "}
                        {item.Description}
                      </h6>
                    </div>
                  ))}
              </Slider>
            </div>
            <div className="drop_icon">
              <img src={IcoTalleres} alt="Talleres en Kuimby" />
            </div>
          </div>
        </Link>
        <Link
          to="/audience"
          className="col-lg-4 col-md-6 col-sm-12 link_data_home"
        >
          <h5 className="mb-1 text fnt_medium">Woman Talks</h5>
          <div className="item_data_home shadows">
            <div className="box_company_home">
              <div className="label_data text-muted mb-1">Próximos Eventos</div>
              <Slider {...settings}>
                {auditoriumItems &&
                  auditoriumItems.map((item) => (
                    <div
                      className="item_cards_directory"
                      id={item.auditoriumId}
                    >
                      <h6 className="mb-1 text fnt_medium">
                        <i className="far fa-calendar"></i> {item.title}
                      </h6>
                      <h6 className="label_data text-muted">
                        {" "}
                        {moment(new Date(item.conferenceDate)).format("LLLL")}
                      </h6>
                    </div>
                  ))}
              </Slider>
            </div>
            <div className="drop_icon">
              <img src={IcoAudience} alt="Company" />
            </div>
          </div>
        </Link>
        <Link to="/directory" className="col-lg-4 col-md-12 link_data_home">
          <h5 className="mb-1 text fnt_medium">Directorio</h5>

          <div className="item_data_home shadows">
            <div className="box_company_home">
              <div className="label_data text-muted mb-1">
                Comunidad empresarial
              </div>
              <Slider {...settings}>
                {directoryItems &&
                  directoryItems.map((item) => (
                    <div
                      className="item_cards_directory"
                      id={item.directoryInfoId}
                    >
                      <h6 className="mb-1 text fnt_medium">
                        <i className="far fa-user"></i> {item.fullName}
                      </h6>
                      <h6 className="mb-0 text">
                        {" "}
                        {item.categoryServiceName} -{" "}
                        {
                          item.directoryContacts.find(
                            (tel) => tel.contactTypeId == 1
                          ).contactValue
                        }
                      </h6>
                      <h6 className="label_data text-muted">
                        {" "}
                        {item.location}
                      </h6>
                    </div>
                  ))}
              </Slider>
            </div>
            <div className="drop_icon">
              <img src={IcoDirectory} alt="Company" />
            </div>
          </div>
        </Link>
      </div>
      <div className="row row_home mb-3">
        <div className="col-sm-12 col-lg-8 mb-1">
          <SliderHome />
        </div>
        <div className="col-sm-12 col-lg-4 mb-1 ">
          <BoxStories
            tituloPost={lastPost && lastPost.title}
            urlImg={lastPost && lastPost.urlImage}
            linkURL={`/our-company/2/${lastPost.companyPostId}`}
          />
        </div>
      </div>
      <div className="row row_drops for_sec_slick settingsWorld">
        <div className="col-lg-8 mb-3 sec_slick">
          <h5 className="text fnt_medium mb-2">Nosotras</h5>
          <Slider {...settingsWorld}>
            {ourWorld &&
              ourWorld.map((itemour) => (
                <BoxStories
                  tituloPost={itemour.title}
                  urlImg={itemour.urlImage}
                  linkURL={`/our-company/1/${itemour.companyPostId}`}
                />
              ))}
          </Slider>
        </div>
        <div className="col-lg-4 mb-3">
          <h5 className="text fnt_medium mb-2">Calendario</h5>
          <BoxSchedule />
        </div>
      </div>

      {/* <section className={`wrapper_modal_doodle ${myModal}`}>
        <div className="modal_content">
          <span className="btnInCLose text fnt_medium" onClick={setDoodle} >
            <i className="fas fa-times"></i> CERRAR
          </span>
          <img src={ImgDoodle} alt="THE PROM" />
        </div>
      </section> */}
    </section>
  );
}
