import React, { useState } from "react";
import "./caringCommunity.scss";
import Moment from "react-moment";
import "moment/locale/es";
import { ItemCaring } from "./itemCaring";
import { ToolDiscount } from "../../components/toolDiscount/toolDiscount";

export function CaringCommunity(props) {
  return (
    <section className="wrapper_surveys wrapper_caring padd">
      <div className="content_surveys w-100">
        <div className="row align-items-center mb-2">
          <div className="col">
            <h2 className="mb-0 text fnt_medium ">Causa con el corazón</h2>
          </div>
          <div className="col text-muted box_more_survey">
            <span className="d-none d-sm-block">
              <Moment locale="es" format="DD [de] MMMM  YYYY">
                {Date.now()}
              </Moment>
              <ToolDiscount isCaringCommunity={true} />
            </span>
          </div>
        </div>
        <p className="drawer_reg_sex">
          Sabemos que aún hay muchas causas que se pueden apoyar.
          <br />
          Si conoces alguna compártenos su información por chat a Concanaco Mx
          Administrador.
        </p>

        {/* {process.env.REACT_APP_ENVIROMENT == "prod" && ( */}
          {/* <div className="wrapper_iframe_powerBi">
            <iframe
              title="Dashboard prueba"
              width="600"
              height="373.5"
              src="https://app.powerbi.com/view?r=eyJrIjoiNjk4N2JjMTUtNTQxYS00OGQyLWI0M2ItM2RiZWFlM2UyNDlmIiwidCI6IjkzNTk1ZjgyLWNhMzgtNDZiMy1hOTZlLTgzY2FjYTI5ZTMwNSJ9&pageName=ReportSection"
              frameborder="0"
              allowFullScreen="true"
            ></iframe>
          </div> */}
        {/* )} */}
        <ItemCaring />
      </div>
    </section>
  );
}
