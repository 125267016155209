import React, { useState, useEffect } from "react";
import { categoryServiceService } from "../../services/categoryService.service";
import { ItemSocial } from "./itemSocial";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./interestGroup.scss";

export function InterestGroup() {
  const roleName = useSelector((state) => state.userSettings.roleName);
  return (
    <section className="wrapper_embassador padd">
      <div className="mb-4 divtop">
        <div className="box_ttl_opt">
          <h2 className="mb-0 text fnt_medium w-100">Grupos de Interés</h2>
          <div className="wrp_filter_directory"></div>
        </div>
        <h4 className="ttls fw600 mb-4">
          <div className="box_tl">
            <span className="ico_shop">
              <i className="fas fa-user"></i>
            </span>
            <span className="pl-2 pr-2">
              Grupos donde nuestra comunidad es protagonista
            </span>
          </div>
        </h4>
      </div>

      <div className="row">
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/InterestGroup/Onu%20Mujeres.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                ONU MUJERES
              </h6>
              <br></br>
              <div className="box_data">
                {/* <h6 className="h_line text fnt_medium ">
                <span>DATOS DE CONTACTO</span>
              </h6>
              <div className="item_data_details">
                <span className="ico_box_detail">
                  <ion-icon name="mail-outline"></ion-icon>
                </span>
                  <a
                    className="alinks text-break text-left"
                    href={`mailto:oubmex@gmail.com`}
                  >
                    oubmex@gmail.com
                  </a>
              </div> */}
                <hr />
                <div className="wrapper_social_links">
                  <ItemSocial
                    socialIcoName="web"
                    socialIco="globe-outline"
                    socialUrl="https://www.unwomen.org/es"
                  />

                  <ItemSocial
                    socialIcoName="Facebook"
                    socialIco="logo-facebook"
                    socialUrl="https://www.facebook.com/onumujeres/"
                  />
                </div>
              </div>
            </div>
          </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/InterestGroup/We%20Connect.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium">WE CONNECT</h6>
              <br></br>
              <br></br>
              <div className="box_data">
                {/* <h6 className="h_line text fnt_medium ">
                    <span>DATOS DE CONTACTO</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                      <ion-icon name="mail-outline"></ion-icon>
                    </span>
                      <a
                        className="alinks"
                        href={`mailto:ednajimenezrb@gmail.com`}
                      >
                        ednajimenezrb@gmail.com
                      </a>

                  </div> */}

                <hr />
                <div className="wrapper_social_links">
                  <ItemSocial
                    socialIcoName="Web"
                    socialIco="globe-outline"
                    socialUrl="https://weconnectinternational.org/latin-america/mexico/"
                  />
                </div>
              </div>
            </div>
          </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/InterestGroup/Mujeres%20PYME.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium">
                CONOCE EL PROGRAMA MUJERES PYME
              </h6>
              <br></br>
              <div className="box_data">
                {/* <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6>
                    <div className="item_data_details">
                      <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a
                          className="alinks"
                          href={`mailto:anadep.lgbt@gmail.com`}
                        >
                          anadep.lgbt@gmail.com
                        </a>

                    </div> */}
                <hr />
                <div className="wrapper_social_links">
                  <ItemSocial
                    socialIcoName="Web"
                    socialIco="globe-outline"
                    socialUrl="https://www.gob.mx/se/articulos/conoce-el-programa-mujeres-pyme"
                  />
                </div>
              </div>
            </div>
          </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/872/InterestGroup/ConcanacoServytur.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium">
                CONCANACO SERVYTUR
              </h6>
              <br></br>
              <br></br>
              <div className="box_data">
                {/* <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6>
                    <div className="item_data_details">
                      <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a
                          className="alinks"
                          href={`mailto:anadep.lgbt@gmail.com`}
                        >
                          anadep.lgbt@gmail.com
                        </a>

                    </div> */}
                <hr />
                <div className="wrapper_social_links">
                  <ItemSocial
                    socialIcoName="Web"
                    socialIco="globe-outline"
                    socialUrl="https://www.concanaco.com.mx/"
                  />
                  <ItemSocial
                    socialIcoName="Facebook"
                    socialIco="logo-facebook"
                    socialUrl="https://es-la.facebook.com/Concanaco/"
                  />
                  <ItemSocial
                    socialIcoName="Instagram"
                    socialIco="logo-instagram"
                    socialUrl="https://www.instagram.com/concanaco.oficial/"
                  />
                  <ItemSocial
                    socialIcoName="Youtube"
                    socialIco="logo-Youtube"
                    socialUrl="https://www.youtube.com/user/concanacoservytur"
                  />
                  <ItemSocial
                    socialIcoName="TikTok"
                    socialIco="logo-TikTok"
                    socialUrl="https://www.tiktok.com/@concanaco_oficial"
                  />
                  <ItemSocial
                    socialIcoName="Linkedin"
                    socialIco="logo-LinkedIn"
                    socialUrl="https://www.linkedin.com/company/concanaco-servytur-mexico/"
                  />
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </section>
  );
}
